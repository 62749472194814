// @flow
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { ComparablePlanType } from '@coverright/data-access/types/medicare';
import { Trash } from '@phosphor-icons/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { usePlansForCompare } from '@coverright/features';
import { useAppContext } from '@coverright/shared/contexts';

type Props = {
  style?: React.CSSProperties;
  quoteId: string;
  clientId: string;
};

export function SelectedPlansList({ style, clientId, quoteId }: Props) {
  const appContext = useAppContext();
  const { maPlans, pdpPlans, mgPlans, removePlan } = usePlansForCompare(appContext, quoteId, clientId);

  return (
    <OverlayScrollbarsComponent
      style={style}
      options={{ paddingAbsolute: true }}
      defer
    >
      <Stack spacing={4}>
        {mgPlans.count > 0 && (
          <Group
            title={`Standalone Drug Plans (${mgPlans.count})`}
            type={ComparablePlanType.Mg}
          >
            {!mgPlans.plans.length &&
              Array(mgPlans.count)
                .fill(1)
                .map((d, i) => (
                  <Skeleton
                    variant={'rounded'}
                    key={i}
                    width={'100%'}
                    height={52}
                  />
                ))}
            {mgPlans.plans.map((plan) => (
              <PlanRow
                key={plan.key}
                title={'Plan ' + plan.planName}
                subtitle={plan.parentOrgName || ''}
                onDeleteClick={() =>
                  removePlan(ComparablePlanType.Mg, plan.key)
                }
              />
            ))}
          </Group>
        )}

        {pdpPlans.count > 0 && (
          <Group
            title={`Standalone Drug Plans (${pdpPlans.count})`}
            type={ComparablePlanType.Pdp}
          >
            {!pdpPlans.plans.length &&
              Array(pdpPlans.count)
                .fill(1)
                .map((d, i) => (
                  <Skeleton
                    variant={'rounded'}
                    key={i}
                    width={'100%'}
                    height={52}
                  />
                ))}
            {pdpPlans.plans.map((plan) => (
              <PlanRow
                key={plan.bidId}
                title={plan.planName || ''}
                subtitle={''}
                onDeleteClick={() =>
                  removePlan(ComparablePlanType.Pdp, plan.bidId)
                }
              />
            ))}
          </Group>
        )}

        {maPlans.count > 0 && (
          <Group
            title={`Medicare Advantage (${maPlans.count})`}
            type={ComparablePlanType.Ma}
          >
            {!maPlans.plans.length &&
              Array(maPlans.count)
                .fill(1)
                .map((d, i) => (
                  <Skeleton
                    variant={'rounded'}
                    key={i}
                    width={'100%'}
                    height={52}
                  />
                ))}
            {!!maPlans.plans.length &&
              maPlans.plans.map((plan) => (
                <PlanRow
                  key={plan.bidId}
                  title={plan.planName || ''}
                  subtitle={''}
                  onDeleteClick={() =>
                    removePlan(ComparablePlanType.Ma, plan.bidId)
                  }
                />
              ))}
          </Group>
        )}
      </Stack>
    </OverlayScrollbarsComponent>
  );
}

const PlanRow = ({
  title,
  subtitle,
  onDeleteClick,
}: {
  title: string;
  subtitle?: string;
  onDeleteClick: () => void;
}) => (
  <Box
    sx={{
      display: 'flex',
      gap: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
      p: '8px 12px',
      bgcolor: '#F3F4F6',
      borderRadius: '8px',
    }}
  >
    <Stack spacing={1} alignItems={'center'} direction={'row'}>
      <Typography color={'#4B5563'}>
        <b>{title}</b>
      </Typography>
      {subtitle && <Typography color={'text.secondary'}>{subtitle}</Typography>}
    </Stack>
    <IconButton onClick={onDeleteClick}>
      <Trash size={20} color={'#1C434F'} />
    </IconButton>
  </Box>
);

const Group = ({
  type,
  title,
  children,
}: PropsWithChildren<{ type: ComparablePlanType; title: string }>) => {
  const color =
    type === ComparablePlanType.Ma
      ? '#F9E69A'
      : type === ComparablePlanType.Mg
      ? '#CBE4EC'
      : '#E1D9C7';

  return (
    <div>
      <Stack spacing={1} alignItems={'center'} direction={'row'} mb={2}>
        <Typography
          variant={'h4'}
          sx={{
            bgcolor: color,
            px: 0.8,
            fontSize: 12,
            letterSpacing: 0.5,
            lineHeight: '20px',
            borderRadius: '6px',
          }}
        >
          <b>{type}</b>
        </Typography>
        <Typography color={'#4B5563'}>
          <b>{title}</b>
        </Typography>
      </Stack>
      <Stack spacing={1}>{children}</Stack>
    </div>
  );
};
